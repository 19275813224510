import TranHistory from "../Components/TranHistory";
import UseLocalStorage from "../Components/UseLocalStorage";

const Transaction = () => {
    const from = "Transaction";
    const [accounts, setAccounts] = UseLocalStorage("Accounts", "");
    const [currNet, setCurrNet] = UseLocalStorage("Network", "");
    const key = "Transaction";
    return (
        <div>
            <h1 className="text-[25px] font-bold text-center">Transaction Cap</h1>
            <TranHistory key={key} from={from} address={accounts.accoutList[accounts.current-1].address} currNet={currNet} />
        </div>
    );
}

export default Transaction;