import { IoMdClose } from "react-icons/io";
import Networks from "../Constant";



const NetworkDialog = ({setNetDialogOpen, setCurrNet}) => {

    const infuraNetworks = [
        {
            name: "Ethereum Mainnet",
            chainId: 1,
            net: Networks.MAINNET,
        },
        {
            name: "Goerli Testnet",
            chainId: 5,
            net: Networks.GOERLI
        },
        {
            name: "Sepolia Testnet",
            chainId: 11155111,
            net: Networks.SEPOLIA
        },
        {
            name: "Polygon Mainnet",
            chainId: 137,
            net: Networks.POLYGON
        }
    ];

    function changeNetwork(element) {
        setCurrNet(element.net);
        setNetDialogOpen(false);
    }

    console.log(infuraNetworks[0].rpcUrl);
    return (
        <div className="flex-col bg-slate-800 p-5 border-0 rounded-md w-[350px] h-fit">
            <div className="flex justify-between m-2 items-center mb-5"><p className="text-lg font-bold m-auto">Networks</p>
                <IoMdClose onClick={() => setNetDialogOpen(false)} ></IoMdClose>
            </div>

            {infuraNetworks.map((element, index) => (
                <div onClick={() => changeNetwork(element)} key={index} className="p-3 border-b-[1px] border-gray-400 hover:bg-slate-900">
                    <p className="text-[14px] font-semibold">{element.name}</p>
                    <p className="text-[12px]">Chain Id: {element.chainId}</p>
                </div>
            ))
            }
        </div>
    )
}


export default NetworkDialog;