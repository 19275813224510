import { BiTransferAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

const TranHistory = ({ from, address, currNet }) => {

    const [tranHistory, setTranHistory] = useState([]);
    const [isDataFetched, setDataFetched] = useState(false);
    const limit = from === 'walletHome' ? 2 : 20;
    useEffect(() => {
        getTransaction(limit);
    }, [address, currNet]);

    async function getTransaction(limit) {

        try {
            console.log("from :;;;;;;;;;;;;;;;;;;;;;;;;;;;; ", from);
            const response = await fetch(`https://deep-index.moralis.io/api/v2.2/${address}?chain=${currNet}&order=DESC&limit=${limit}`, {
                headers: {
                    'Accept': 'application/json',
                    'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,  // Replace with your Moralis API key
                },
            });

            if (!response.ok) {
                setDataFetched(false);
                return;
            }

            const result = await response.json();
            // console.log(result);
            setTranHistory(result.result);
            setDataFetched(true);
        } catch (err) {
            setDataFetched(false);
            console.error(err);
        } finally {

        }
    }

    if (isDataFetched) {
        if (tranHistory.length == 0) {
            return <p className="text-center text-[16px] font-semibold w-full p-6">No transaction found.</p>
        } else {
            return (
                <>
                    {
                        tranHistory.map((element, index) => {
                            return (
                                <div className="text-start flex items-center justify-between p-3 border-b-[1px] border-gray-400 hover:bg-slate-900">
                                    <div className="flex-col justify-start">
                                        <p className="text-[12px] font-thin">{element.block_timestamp.slice(0, 10)}</p>
                                        <div className="flex">
                                            <BiTransferAlt className="text-white m-1" size="23" />
                                            <div className="flex-col">
                                                <p className="text-[15px] font-semibold m-1">{element.input == "0x" ? (element.from_address == address.toLowerCase() ? "Transfer" : "Received") : "Smart Contract Interaction"}</p>
                                                <p className={`text-[12px] m-1 font-semibold ${element.receipt_status === '1' ? 'text-green-500' : 'text-red-600'}`}>{element.receipt_status === '1' ? 'Success' : 'Failed'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-[14px] font-bold">{ethers.formatEther(element.value).slice(0, 5)} Eth</p>

                                </div>
                            )
                        })
                    }
                    {from == "walletHome" ? <div className="flex items-center justify-center mt-4 mb-2">
                        <p className='cursor-pointer font-semibold text-[18px] w-[130px] border-2 border-blue-800 rounded-3xl bg-blue-800 py-2 px-3 text-white  hover:border-blue-950'>View More</p>
                    </div> : <> </>}
                </>
            )
        }
    } else {
        return (
            <>
            {Array.from({ length: limit }).map((_, index) => (
              <div key={index} className="p-4 bg-[#374151] rounded shadow  ">
                <div className="animate-pulse flex space-x-4">
                  <div className="rounded-full bg-gray-600 h-12 w-12" />
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-600 rounded w-3/4" />
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-600 rounded" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )
    }



}


export default TranHistory;