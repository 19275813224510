import { useEffect, useState } from "react";
import { BiDownArrow } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdOutlineFileCopy } from "react-icons/md";
import { RiSendPlaneLine } from "react-icons/ri";
import receive_img from "../images/received-svgrepo-com.png"
import CommonDialog from "../Dialogs/CommonDialog";
import NetworkDialog from "../Dialogs/NetworkDialog";
import TranHistory from "../Components/TranHistory";
import AccountDialog from "../Components/AccountDialog";
import SendDialog from "../Dialogs/SendDialog";
import UseLocalStorage from "../Components/UseLocalStorage";
import { ethers } from "ethers";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const WalletHome = () => {
    const [ethBalance, setEthBalance] = useState('');
    const [accounts, setAccounts] = UseLocalStorage("Accounts", "");
    const [mnemonic, setMnemonic] = UseLocalStorage("Mnemonic", "");
    const [isNetDialogOpen, setNetDialogOpen] = useState(false);
    const [isAccountDialogOpen, setAccountDialogOpen] = useState(false);
    const [isSendDialogOpen, setSendDialogOpen] = useState(false);
    const API_KEY = process.env.REACT_APP_INFURA_API_KEY;
    const [currNet, setCurrNet] = UseLocalStorage("Network", "");
    const [provider, setprovider] = useState(null);
    const [wallet, setwallet] = useState(null);
    const [tranKey, setTranKey] = useState(1);
    const address = accounts.accountList[accounts.current-1].address;



    const transferEth = async (recepeintAddress, eth) => {
        const tx = {
            to: recepeintAddress,
            value: ethers.parseEther(parseFloat(eth).toString())
        };
        const toastId = toast.loading('Processing Transaction...', {
            isLoading: true,
            autoClose: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            position: "bottom-right"
        });
        try {
            const txResponse = await wallet.sendTransaction(tx);
            console.log('Transaction Response:', txResponse);
            toast.update(toastId, {
                render: 'Signing Transaction...',
                isLoading: true,
                pauseOnHover: false,
                closeOnClick: false,
                theme: "light",
                autoClose: false,
                transition: Slide,
            });

            // Wait for the transaction to be mined
            const txReceipt = await txResponse.wait();
            console.log('Transaction Receipt:', txReceipt);
            if (txReceipt.status !== 1) throw new Error('Transaction rejected');
            toast.update(toastId, {
                render: 'Transaction completed successfully!',
                type: 'success',
                theme: 'light',
                isLoading: false,
                autoClose: 3000,
            });
            fetchBalance();
            setTranKey(tranKey+1);
        } catch (error) {
            console.error('Error sending transaction:', error);
            toast.update(toastId, {
                render: 'Transaction failed! ' + error.message,
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    };

    useEffect(() => {
        console.log("Rpc url : " + `https://${currNet}.infura.io/v3/${API_KEY}`);
        let provider = new ethers.JsonRpcProvider(`https://${currNet}.infura.io/v3/${API_KEY}`);
        setprovider(provider);
        console.log(accounts.accountList[accounts.current - 1].privatekey);
        let wallet = new ethers.Wallet(accounts.accountList[accounts.current - 1].privatekey, provider);
        setwallet(wallet);
        fetchBalance(provider,wallet);
        setTranKey(tranKey+1);
        console.log("al;dskjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
    }, [currNet, accounts]);

    const fetchBalance = async (provider,wallet) => {
        try {
            console.log(provider);
            const ethBalance = await provider.getBalance(wallet.address);
            setEthBalance(ethers.formatEther(ethBalance).substring(0, 6) + " " + currNet + "ETH");
        } catch (error) {
            console.error("Error fetching balance: ", error);
        }
    };

    const copyAddress = () => {
        navigator.clipboard.writeText(accounts.accountList[accounts.current - 1].address).then(() => {
            toast.success("Address copied to clipboard!", {
                position: "bottom-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "light",
                transition: Slide,
            });
        }).catch(err => {
            console.error("Error copying text: ", err);
        });
    }


    return (
        <>
            <ToastContainer />
            <div className="relative bg-[#374151] min-h-screen max-h-full text-white p-[1px]">

                <CommonDialog isOpen={isNetDialogOpen} >
                    <NetworkDialog setNetDialogOpen={setNetDialogOpen} setCurrNet={setCurrNet} />
                </CommonDialog>

                <CommonDialog isOpen={isAccountDialogOpen} >
                    <AccountDialog setAccountDialogOpen={setAccountDialogOpen} accounts={accounts} setAccounts={setAccounts} mnemonic={mnemonic} />
                </CommonDialog>

                <CommonDialog isOpen={isSendDialogOpen} >
                    <SendDialog setSendDialogOpen={setSendDialogOpen} address={accounts.accountList[accounts.current - 1].address} currBalance={ethBalance}
                        provider={provider} transferEth={transferEth} />
                </CommonDialog>

                <div className="flex flex-col-reverse md:flex-row md:justify-between md:ml-10 md:mr-10 items-center mt-3 md:mt-8">
                    <div onClick={() => setAccountDialogOpen(true)} className="mt-2 md:mt-0 bg-slate-800 pt-2 pb-2 pr-5 pl-5 border-0 rounded-md flex justify-between items-center w-[200px]">
                        <p className="text-[18px] md:text-[20px] ">Account {accounts.current} </p>
                        <BiDownArrow className="text-white" size="15" />
                    </div>
                    <div onClick={() => setNetDialogOpen(true)} className="bg-slate-800 pt-2 pb-2 pr-3 pl-3 border-0 rounded-full flex justify-around items-center w-auto">
                        <p className="text-[12px] md:text-[14px] mr-2">{currNet} </p>
                        <GoDotFill className="text-green-600 mr-2" size="12" />
                        <MdKeyboardArrowDown className="text-white" size="18" />
                    </div>
                </div>

                <div className="text-center h-fit pb-10 md:h-[300px] flex-col mt-10 md:mt-20 text-white">
                    <div className="bg-[#2a323d] pt-2 pb-2 pr-5 pl-5 border-0 rounded-2xl flex justify-between items-center w-fit m-auto">
                        <p className="text-[14px] mr-4">{address.substring(0,5)}...{address.substring(address.length-5, address.length)}</p>
                        <div onClick={copyAddress} className="bg-[#374151] cursor-default pt-1 pb-1 pr-2 pl-2 border-0 rounded-2xl flex justify-between items-center w-fit">
                            <p className="text-[13px] m-1">Copy</p>
                            <MdOutlineFileCopy className="text-white" size="17" />
                        </div>
                    </div>
                    <div className="mt-2 md:mt-10 text-center">
                        {ethBalance == '' ?
                            <span className="bg-gray-900 w-[150px] mt-2 h-[40px] animate-pulse rounded-sm mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span> :
                            <p className="text-[25px] font-bold">{ethBalance}</p>
                        }

                    </div>

                    <div className="mt-10 text-center flex justify-center">
                        <div onClick={() => setSendDialogOpen(true)} className="flex items-center justify-center mr-10 md:mr-16 w-[130px] cursor-pointer border-2 border-blue-800 rounded-3xl bg-blue-800 py-2 px-3 text-white  hover:border-blue-950">
                            <p className='cursor-pointer font-semibold text-[18px] mr-2'>Send</p>
                            <RiSendPlaneLine className="text-white" size="20" />
                        </div>
                        <div onClick={copyAddress} className="flex items-center w-[130px] cursor-pointer border-2 border-blue-800 rounded-3xl bg-blue-800 py-2 px-3 text-white  hover:border-blue-950">
                            <p className='cursor-pointer font-semibold text-[18px] mr-2'>Receive</p>
                            <img src={receive_img} className="fill-white h-[20px]" size="20" />
                        </div>
                    </div>



                </div>

                <div className="mt-4 h-[1px] bg-gray-500"></div>
                <div className="m-5 flex">
                    <p className="text-[25px] font-semibold">Recent Activity</p>
                </div>
                <div className="mt-5 h-[1px] bg-gray-500"></div>

                {tranKey > 0 ? <TranHistory key={tranKey} from={"walletHome"} address={accounts.accountList[accounts.current - 1].address} currNet={currNet}  />:null} 

                
            </div>
        </>
    );
}

export default WalletHome;