import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import UseLocalStorage from "../Components/UseLocalStorage";
import { Provider } from "ethers";


const SendDialog = ({ setSendDialogOpen, address, currBalance, provider, transferEth }) => {


    const [error, setError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [gas, setGas] = useState('');


    const [formValues, setFormValues] = useState({
        address: '',
        amount: 0,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });

        if (name == 'address') {
            if (!ethers.isAddress(value) && value !== address) {
                setError('Invalid wallet address');
            } else {
                setError('');
            }
        } else if (name == 'amount') {
            setGas('')
            if (isNaN(value) || value <= 0 || value == '') {
                // setFormValues({ ...formValues, [name]: '' });
                // setGas('');
            } else {
                if (value > currBalance) {
                    setAmountError('Insufficient balance');
                } else {
                    setAmountError('');
                    estimateGas(value);
                }
            }
        }
    };

    const estimateGas = async (value) => {

        if (formValues.address == "") return;

        const tx = {
            to: formValues.address,
            value: ethers.parseEther(parseFloat(value).toString())
        };

        try {
            const estimatedGas = await provider.estimateGas(tx);

            // Get the gas price information
            const feeData = await provider.getFeeData();

            // Extract the gas price from feeData
            console.log(feeData);
            const gasPrice = feeData.gasPrice;

            // Calculate the gas fee in ETH (Gas Limit * Gas Price in wei)
            const gasFeeInWei = estimatedGas * gasPrice;

            // Convert wei to ether (divide by 10^18)
            const gasFeeInEth = ethers.formatEther(gasFeeInWei);

            setGas(gasFeeInEth);
            console.log(`Estimated Gas: ${estimatedGas.toString()}`);
        } catch (error) {
            console.error('Error estimating gas:', error);
        }
    };

    const sendEth = () => {
        if (error == '' && amountError == '' && formValues.amount > 0) {
            transferEth(formValues.address, formValues.amount);
            setSendDialogOpen(false);
        }
    }

    return (
        <>
            <style>
                {
                    `
                    /* styles.css */
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    input[type="number"] {
                    -moz-appearance: textfield;
                    }
                    `
                }
            </style>
            <div className="flex-col bg-slate-800 p-5 border-0 rounded-md w-[95%] md:w-[550px] h-fit">
                <div className="flex justify-between m-2 items-center"><p className="text-lg font-bold m-auto">Send Ethereum</p>
                    <IoMdClose onClick={() => setSendDialogOpen(false)}></IoMdClose>
                </div>
                <div className="h-[1px] w-full bg-gray-600 mb-4 mt-2"></div>

                <div className="flex gap-5 justify-between items-center m-2">
                    <label className="text-[12px] font-semibold mr-3">From:</label>
                    <input type="text" value={address} inputMode="none" disabled className="border-2 w-[78%] bg-[#2a323d] text-[12px] border-gray-400 p-2 rounded-lg focus:outline-none" />
                </div>

                <div className="flex gap-5 justify-between items-center m-2">
                    <label className="text-[12px] font-semibold mr-3">To:</label>
                    <input name="address" type="text" value={formValues.address} placeholder="Recepient address" onChange={handleChange} className="border-2 w-[78%] bg-[#2a323d] text-[12px] border-gray-400 p-2  rounded-lg focus:outline-none" />
                </div>
                {error && <p className="text-red-700 text-sm font-light text-right pr-2">{error}</p>}

                <div className="flex gap-5 justify-between items-center m-2">
                    <label className="text-[12px] font-semibold mr-3">Ethereum:</label>
                    <input name="amount" type="number" placeholder="Amount" value={formValues.amount} onChange={handleChange} className="border-2 w-[78%] bg-[#2a323d] border-gray-400 p-2 text-[12px] rounded-lg focus:outline-none" />
                </div>
                {amountError && <p className="text-red-700 text-sm font-light text-right pr-2">{amountError}</p>}
                <div className="h-[1px] w-full bg-gray-600 mb-4 mt-4"></div>

                <div className="flex gap-5 justify-between items-center m-2">
                    <label className="text-[12px] font-semibold mr-3">Estimation Gas:</label>

                    {gas == '' ? (<span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse rounded-sm mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>) : (<span className="text-gray-400 text-[12px] font-semibold">{gas}</span>)}

                </div>

                <div onClick={sendEth} className="mt-5 flex items-center justify-center w-full cursor-pointer border-2 border-blue-800 rounded-3xl bg-blue-800 py-1 text-white  hover:border-blue-950">
                    <p className='cursor-pointer font-semibold text-[15px]'>Send</p>
                </div>
            </div>
        </>
    );
}



export default SendDialog;